:root {
    --mainColor: #ff4157;
    --bodyColor: #121b22;
    --cardColor: #19232a;
    --font-primary: "Poppins", sans-serif;
    --font-secondary: "Roboto", sans-serif;
    --footerColor: var(--bodyColor);
    --headerColor: #111b23;
    --fontColor: #ffffff ;
    --secondaryFontColor: #a2a9bf;
    --headerFontColor: #ffffff;
}

@import "./css/vendor.bundle";
@import "./css/style";
@import "./css/theme";

.feature-icon{
    img{
        width: 49px;
        height: 49px;
        object-fit: contain;
    }
}

.project-s4{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;

    .filtr-item:nth-child(1){
        grid-row: 1/3;
    }
    .project-item{
        height: 100%;
        .project-image{
            height: 100%;
            img{
                height: 100%;
                object-fit: cover;
            }
        }
    }
}
.padding-0{
    padding: 0;
}
.application-modal{
    padding: 30px;
}

.form-result{
    position: absolute;
    background-color: #19232a;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    opacity: 0;
    visibility: hidden;
    transition: .3s;
}


.form-visible{
    opacity: 1;
    visibility: visible;
}
.red{
    color: #a11e1e !important;
}

.accordion-bdr{
    .accordion-item{
        border: none !important;
        background-color: #19232a;
    }   
    .accordion-button{
        background-color: #19232a;
        border: none !important;
        color: #ffffff !important;
        border-radius: 0 !important;
        padding: 20px 30px;
        font-weight: 700;
        font-size: 1.125rem;
    }
}

.post-service img{
    height: 230px;
    object-fit: cover;
}

#root{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

footer{
    margin-top: auto;
}

.search-show{
    opacity: 1;
    visibility: visible;
}

.project-image{
    img{
        height: 350px;
        object-fit: cover;
    }
}
@media(max-width: 1600px){
    .project-image{
        img{
            height: 300px;
        }
    }
    
}
@media(max-width: 1340px){
    .project-image{
        img{
            height: 260px;
        }
    }
}
@media(max-width: 1200px){
    .project-image{
        img{
            height: auto;
        }
    }
}

@media (max-width: 992px){
    .header-nav-toggle{
        display: flex;
        align-items: center;
    }
    
}

.header-navbar{
    z-index: 100;
}
.header-wrapper{
    background-color: #00000082;
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;

}

.open-nav{
    ul{
        display: block !important;
    }
}


@media(max-width: 767px){
    .project-s4{
        display: flex;
        flex-direction: column;
    }
}

.footer{
    background-color: var(--footerColor);
}